import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import router from "@/router";

Sentry.init({
  Vue,
  dsn: "https://202da5719d9449c796db3f78c4e33a3b@glitchtip.kruzhok.org/4",
  release: `onti-front@${process.env.VUE_APP_VERSION}`,
  environment: process.env.VUE_APP_ENV || "production",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "my.ntcontest.ru",
        "stage.ntcontest.ru",
        "talent.kruzhok.org",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
  beforeBreadcrumb(crumb) {
    // удаляю крошки про вебвизор, их много
    if (
      crumb?.category === "xhr" &&
      crumb?.data?.url?.startWith("https://mc.yandex.ru/")
    ) {
      return null;
    }
    return crumb;
  },
});
